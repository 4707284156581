import React, { useState } from 'react';
import { ChevronRight, Check, ShieldCheck } from 'lucide-react';
import Button from '../components/ui/button';
import Input from '../components/ui/input';
import Select from '../components/ui/select';
import { Alert } from '../components/ui/alert';
import toast, { Toaster } from 'react-hot-toast';

const appUrl = process.env.REACT_APP_URL;
const DEFAULT_CURRENCY = 'GBP';

const SevenDays = () => {
  const [email, setEmail] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const trialPlan = { duration: '7 Days', price: 4.99, currency: DEFAULT_CURRENCY };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsProcessing(true);

    try {
      const orderData = {
        plan: trialPlan.duration,
        email: email.trim().toLowerCase(),
        deviceType,
        paymentMethod,
        amount: trialPlan.price,
        currency: DEFAULT_CURRENCY,
        domain: window.location.hostname,
        paymentStatus: 'Pending',
      };

      if (!orderData.email || !orderData.deviceType || !orderData.paymentMethod) {
        throw new Error('Please fill in all required fields');
      }

      // Send initial order data to Website B
      const responseB = await fetch(`${appUrl}/api/checkout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderData),
      });

      const resultB = await responseB.json();

      if (!responseB.ok) {
        throw new Error(resultB.message || 'Order processing failed on Website B');
      }

      // Redirect if payment initiation was successful
      if (resultB.success && resultB.redirectUrl) {
        window.location.href = resultB.redirectUrl;
      } else {
        throw new Error('Invalid response from Website B');
      }

    } catch (error) {
      console.error('Checkout error:', error);
      setError(error.message || 'An error occurred during checkout');
      toast.error(error.message || 'Please try again later');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 flex items-center justify-center py-8">
      <Toaster position="top-right" />
      <div className="container mx-auto px-8 grid lg:grid-cols-4 gap-8">
        
        {/* Left Section: Form (75%) */}
        <div className="lg:col-span-3 bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-extrabold mb-6 text-[#ff6b6b]">
            7-Day Trial for £4.99
          </h2>

          {error && (
            <Alert variant="destructive" className="mb-6">
              <span>{error}</span>
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            {/* Email */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="email">
                Email
              </label>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full py-4"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* Device Type */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="device">
                Device Type
              </label>
              <Select
                id="device"
                value={deviceType}
                onValueChange={setDeviceType}
                required
              >
                <Select.Item value="amazon">Amazon Fire Stick / Fire TV</Select.Item>
                <Select.Item value="smarttv">Smart TV</Select.Item>
                <Select.Item value="android">Android Device</Select.Item>
                <Select.Item value="magbox">Mag Box / Formular Z8</Select.Item>
                <Select.Item value="apple">Apple Device / Mac OS</Select.Item>
                <Select.Item value="windows">Windows Device</Select.Item>
                <Select.Item value="enigma">Enigma / Zgemma</Select.Item>
                <Select.Item value="m3u">M3u Url</Select.Item>
                <Select.Item value="other">Other Device</Select.Item>
              </Select>
            </div>

            {/* Payment Method */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Payment Method</label>
              <div className="flex space-x-4">
                <button
                  type="button"
                  className={`w-1/2 p-3 rounded-lg border-2 flex items-center justify-center space-x-2 ${
                    paymentMethod === 'paypal' ? 'border-[#ff6b6b] bg-[#ff6b6b] text-white' : 'border-gray-300'
                  }`}
                  onClick={() => setPaymentMethod('paypal')}
                >
                  <img
                    src="https://res.cloudinary.com/benejma/image/upload/v1726676015/paypal_qtp6ny.png"
                    alt="PayPal"
                    className="w-5 h-5"
                  />
                  <span>PayPal</span>
                </button>
                <button
                  type="button"
                  className={`w-1/2 p-3 rounded-lg border-2 flex items-center justify-center space-x-2 ${
                    paymentMethod === 'creditcard' ? 'border-[#ff6b6b] bg-[#ff6b6b] text-white' : 'border-gray-300'
                  }`}
                  onClick={() => setPaymentMethod('creditcard')}
                >
                  <img
                    src="https://res.cloudinary.com/benejma/image/upload/v1726676016/atm-card_pffw03.png"
                    alt="Credit Card"
                    className="w-5 h-5"
                  />
                  <span>Credit Card</span>
                </button>
              </div>
              <div className="mt-4 flex items-center space-x-2 text-gray-500 text-sm">
                <ShieldCheck className="text-green-500" />
                <span>Secure payment with SSL encryption</span>
              </div>
            </div>

            {/* Submit Button */}
            <Button
              type="submit"
              className="w-full bg-[#ff6b6b] text-white py-4 rounded-lg font-semibold text-lg flex items-center justify-center hover:bg-[#ff5757] transition-colors duration-200"
              disabled={!email || !deviceType || !paymentMethod || isProcessing}
            >
              {isProcessing ? 'Processing...' : 'CONTINUE'} <ChevronRight className="ml-2" />
            </Button>
          </form>
        </div>

        {/* Right Section: Plan Details (25%) */}
        <div className="lg:col-span-1 bg-white p-8 rounded-lg shadow-lg">
          <h3 className="font-semibold mb-4 text-xl">Your Plan Includes:</h3>
          <ul className="text-sm space-y-3">
            {[
              '54,000 TV Channels, Movies & Series',
              '4K / Ultra HD / HD Picture Quality',
              'Catch Up / EPG For All Channels',
              'International Live & Sports',
              '99.9% Uptime Servers',
              'Video-on-Demand & Series Package',
              'All Devices Supported',
              'Adult Channels - Switch ON/OFF',
            ].map((feature, index) => (
              <li key={index} className="flex items-start">
                <Check className="text-green-500 mr-2 mt-1 flex-shrink-0" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SevenDays;
