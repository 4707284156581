import React, { useState, useRef } from 'react';
import { ChevronRight, Check, Info, ShieldCheck, AlertCircle } from 'lucide-react'; 
import Button from '../components/ui/button';
import Input from '../components/ui/input';
import Select from '../components/ui/select';
import { Alert } from '../components/ui/alert';
import toast, { Toaster } from 'react-hot-toast';

const appUrl = process.env.REACT_APP_URL;
const DEFAULT_CURRENCY = 'GBP';

const CheckoutPage = () => {
  const plans = [
    {
      duration: '1 Month',
      price: 9.99,
      save: '0%',
      guarantee: '7-day',
      lastSold: '80s',
      currency: DEFAULT_CURRENCY,
      perMonth: 9.99
    },
    {
      duration: '12 Months',
      price: 49.99,
      save: '50%',
      guarantee: '30-day',
      lastSold: '72s',
      originalPrice: 139.98,
      totalLabel: 'total',
      currency: DEFAULT_CURRENCY,
      perMonth: 4.17
    },
    {
      duration: '3 Months',
      price: 24.99,
      save: '23%',
      guarantee: '30-day',
      lastSold: '94s',
      originalPrice: 38.97,
      totalLabel: 'total',
      currency: DEFAULT_CURRENCY,
      perMonth: 8.33
    },
    {
      duration: '6 Months',
      price: 34.99,
      save: '36%',
      guarantee: '30-day',
      lastSold: '57s',
      originalPrice: 77.94,
      totalLabel: 'total',
      currency: DEFAULT_CURRENCY,
      perMonth: 5.83
    },
  ];

  const trialPlan = {
    duration: '24 Hours',
    price: 1.99,
    save: '0%',
    guarantee: 'No money-back',
    lastSold: 'Just Now',
    totalLabel: 'Trial',
    currency: DEFAULT_CURRENCY
  };

  const [selectedPlan, setSelectedPlan] = useState(plans[1]);
  const [email, setEmail] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');

  const detailsSectionRef = useRef(null);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    detailsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsProcessing(true);

    try {
      const orderData = {
        plan: selectedPlan.duration,
        email: email.trim().toLowerCase(),
        deviceType,
        paymentMethod,
        amount: selectedPlan.price,
        currency: DEFAULT_CURRENCY,
        domain: window.location.hostname,
        paymentStatus: 'Pending'
      };

      if (!orderData.email || !orderData.deviceType || !orderData.paymentMethod) {
        throw new Error('Please fill in all required fields');
      }

      const response = await fetch(`${appUrl}/api/checkout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderData),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || 'Order creation failed');
      }

      if (result.success && result.redirectUrl) {
        window.location.href = result.redirectUrl;
      } else {
        throw new Error('Invalid response from server');
      }

    } catch (error) {
      console.error('Checkout error:', error);
      setError(error.message || 'An error occurred during checkout');
      toast.error(error.message || 'Please try again later');
    } finally {
      setIsProcessing(false);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: DEFAULT_CURRENCY
    }).format(amount);
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <Toaster position="top-right" />
      <main className="container mx-auto px-6 py-8 pt-24">
        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertCircle className="h-4 w-4" />
            <span>{error}</span>
          </Alert>
        )}

        <section className="mb-12">
          <div className="flex items-center mb-6">
            <span className="bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white px-3 py-1 rounded-full text-sm mr-3">
              Step 1
            </span>
            <h2 className="text-4xl font-extrabold text-[#ff6b6b]">Choose Your Plan</h2>
          </div>

          <Alert className="mb-6">
            <span className="font-semibold">50% Off Today</span> - Limited time offer!
          </Alert>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {plans.map((plan, index) => (
              <div
                key={index}
                className={`relative rounded-lg p-6 cursor-pointer border-2 transition-all duration-300 ${
                  selectedPlan?.duration === plan.duration
                    ? 'bg-[#ffe7e7] border-[#ff6b6b] shadow-lg scale-105'
                    : 'bg-white border-transparent hover:border-[#ff6b6b] hover:shadow-md'
                }`}
                onClick={() => handlePlanSelect(plan)}
              >
                <div className="bg-[#ff6b6b] text-white text-xs px-2 py-1 rounded absolute top-2 right-2">
                  Last SOLD: {plan.lastSold} ago
                </div>
                <h3 className="text-2xl font-bold mb-2">{plan.duration}</h3>
                <div className="text-4xl font-extrabold mb-1">
                  {formatCurrency(plan.price)}
                </div>
                <div className="text-sm mb-4 text-gray-500">
                  {plan.totalLabel ? `${formatCurrency(plan.perMonth)} per month` : 'per month'}
                </div>
                <div className="bg-green-100 text-green-800 text-sm px-2 py-1 rounded inline-block mb-2">
                  SAVE {plan.save}
                </div>
                <div className="text-sm mb-4">{plan.guarantee} money-back guarantee</div>
                {plan.originalPrice && (
                  <div className="text-sm text-gray-600">
                    <span className="line-through">{formatCurrency(plan.originalPrice)}</span>{' '}
                    <span className="font-semibold">{formatCurrency(plan.price)} billed once</span>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="col-span-full flex justify-center mt-6">
            <Button
              onClick={() => handlePlanSelect(trialPlan)}
              className="bg-[#ff6b6b] text-white py-4 px-8 rounded-lg font-semibold text-lg hover:bg-[#ff5757] transition-colors duration-200"
            >
              Get 24-Hour Trial for {formatCurrency(trialPlan.price)}
            </Button>
          </div>
        </section>

        <section ref={detailsSectionRef}>
          <div className="flex items-center mb-6">
            <span className="bg-gradient-to-r from-[#ff6b6b] to-[#ffa500] text-white px-3 py-1 rounded-full text-sm mr-3">
              Step 2
            </span>
            <h2 className="text-4xl font-extrabold text-[#ff6b6b]">Fill Your Details</h2>
          </div>

          <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2 bg-white rounded-lg p-8 shadow-md">
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2" htmlFor="email">
                  Email
                </label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  className="w-full py-4"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isProcessing}
                />
              </div>

              <div className="mb-6">
                <label className="block text-sm font-medium mb-2" htmlFor="device">
                  Device Type
                </label>
                <Select
                  id="device"
                  value={deviceType}
                  onValueChange={setDeviceType}
                  required
                  disabled={isProcessing}
                >
                  <Select.Item value="amazon">Amazon Fire stick / Fire tv</Select.Item>
                  <Select.Item value="smarttv">Smart TV</Select.Item>
                  <Select.Item value="android">Android Device</Select.Item>
                  <Select.Item value="magbox">Mag Box / Formular Z8</Select.Item>
                  <Select.Item value="apple">Apple Device / Mac OS</Select.Item>
                  <Select.Item value="windows">Windows Device</Select.Item>
                  <Select.Item value="enigma">Enigma / Zgemma</Select.Item>
                  <Select.Item value="m3u">M3u Url</Select.Item>
                  <Select.Item value="other">Other Device</Select.Item>
                </Select>
              </div>

              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">Payment Method</label>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className={`w-1/2 p-3 rounded-lg border-2 flex items-center justify-center space-x-2 ${
                      paymentMethod === 'paypal'
                        ? 'border-[#ff6b6b] bg-[#ff6b6b] text-white'
                        : 'border-gray-300'
                    }`}
                    onClick={() => setPaymentMethod('paypal')}
                    disabled={isProcessing}
                  >
                    <img
                      src="https://res.cloudinary.com/benejma/image/upload/v1726676015/paypal_qtp6ny.png"
                      alt="PayPal"
                      className="w-5 h-5"
                    />
                    <span>PayPal</span>
                  </button>
                  <button
                    type="button"
                    className={`w-1/2 p-3 rounded-lg border-2 flex items-center justify-center space-x-2 ${
                      paymentMethod === 'creditcard'
                        ? 'border-[#ff6b6b] bg-[#ff6b6b] text-white'
                        : 'border-gray-300'
                    }`}
                    onClick={() => setPaymentMethod('creditcard')}
                    disabled={isProcessing}
                  >
                    <img
                      src="https://res.cloudinary.com/benejma/image/upload/v1726676016/atm-card_pffw03.png"
                      alt="Credit Card"
                      className="w-5 h-5"
                    />
                    <span>Credit Card</span>
                  </button>
                </div>
                <div className="mt-4 flex items-center space-x-2 text-gray-500 text-sm">
                  <ShieldCheck className="text-green-500" />
                  <span>Secure payment with SSL encryption</span>
                </div>
              </div>

              <div className="bg-gray-50 p-6 rounded mb-6">
                <h3 className="text-xl font-semibold mb-4">Order Summary</h3>
                <div className="flex justify-between mb-2">
                  <span>
                    {selectedPlan
                      ? `${selectedPlan.duration} Pass - All Channels & VOD`
                      : 'No plan selected'}
                  </span>
                  <span>
                    {selectedPlan ? formatCurrency(selectedPlan.price) : formatCurrency(0)}
                  </span>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between font-semibold text-lg">
                  <span>Total</span>
                  <span className="text-[#ff6b6b] text-3xl">
                    {selectedPlan ? formatCurrency(selectedPlan.price) : formatCurrency(0)}
                  </span>
                </div>
              </div>

              <Button
                type="submit"
                className="w-full bg-[#ff6b6b] text-white py-4 rounded-lg font-semibold text-lg flex items-center justify-center hover:bg-[#ff5757] transition-colors duration-200"
                disabled={!selectedPlan || !email || !deviceType || !paymentMethod || isProcessing}
              >
                {isProcessing ? 'Processing...' : 'CONTINUE'} <ChevronRight className="ml-2" />
              </Button>

              <div className="text-center text-sm mt-4 text-gray-600">
                <Check className="inline-block mr-1 text-green-500" size={16} />
                You're 100% backed by our 30-day money-back guarantee.
              </div>
            </div>

            <div className="lg:col-span-1 space-y-6">
              <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="font-semibold mb-4 text-xl">Coupon Code</h3>
                <div className="flex">
                  <Input
                    type="text"
                    className="flex-grow"
                    placeholder="Enter coupon code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                    disabled={isProcessing}
                    maxLength={10}
                  />
                  <Button 
                    className="ml-2"
                    onClick={() => toast("This feature is coming soon!")}
                    disabled={!couponCode || isProcessing}
                  >
                    Apply
                    </Button>
                </div>
              </div>
              {/* Plan Features */}
              <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="font-semibold mb-4 text-xl">Your Plan Includes:</h3>
                <ul className="text-sm space-y-3">
                  {[
                    '54,000 TV Channels, Movies & Series',
                    '4K / Ultra HD / HD Picture Quality',
                    'Catch Up / EPG For All Channels',
                    'International Live & Sports',
                    '99.9% Uptime Servers',
                    'Video-on-Demand & Series Package',
                    'All Devices Supported',
                    'Adult Channels - Switch ON/OFF',
                  ].map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="text-green-500 mr-2 mt-1 flex-shrink-0" size={16} />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Price Guarantee */}
              <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="font-semibold mb-4 text-xl">Price Guarantee</h3>
                <div className="flex items-start space-x-3">
                  <ShieldCheck className="text-green-500 flex-shrink-0" size={24} />
                  <div className="text-sm">
                    <p className="font-medium mb-2">Lowest Price Guaranteed</p>
                    <p className="text-gray-600">
                      We guarantee the lowest prices for our services. If you find a lower price elsewhere, 
                      we'll match it and give you an additional month free.
                    </p>
                  </div>
                </div>
              </div>

              {/* Payment Security */}
              <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="font-semibold mb-4 text-xl">Secure Payment</h3>
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <ShieldCheck className="text-green-500" size={20} />
                    <span className="text-sm">SSL Encrypted Payment</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <ShieldCheck className="text-green-500" size={20} />
                    <span className="text-sm">Secure Payment Processing</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <ShieldCheck className="text-green-500" size={20} />
                    <span className="text-sm">No Hidden Charges</span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </main>

      {/* Help Button */}
      <button 
        className="fixed bottom-4 right-4 bg-white p-2 rounded-full shadow-md hover:shadow-lg transition-shadow duration-200"
        onClick={() => toast("Our support team is available 24/7. Please contact us for assistance.")}
      >
        <Info className="h-6 w-6 text-[#ff6b6b]" />
      </button>
    </div>
  );
};

export default CheckoutPage;

